<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Profil Identitas Pengguna</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <h4 class="mb-10 font-weight-bold text-dark">
            INFORMASI IDENTITAS PENGGUNA
          </h4>
          <!--begin::Input-->
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Nama</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.name"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Username</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.username"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Password</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.password_plain"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Alamat Surat Elektronik</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4">Pendidikan Terakhir</label>
            <div class="col-3">
              <b-form-select
                disabled
                v-model="profil.pendidikan_terakhir"
                :options="pendidikan"
                style="width: 150px"
              ></b-form-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Alamat</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.alamat"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <label>Provinsi</label>
                    <b-form-select disabled v-model="profil.id_ptsp">
                      <option
                        v-for="(dataprovinsi, k) in provinsi"
                        :key="k"
                        :value="dataprovinsi.id"
                      >
                        {{ dataprovinsi.name }}
                      </option>
                    </b-form-select>
                    {{ profil.provinsi }}
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">No Telp</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <input
                      type="number"
                      disabled
                      v-model="profil.no_hp"
                      class="form-control"
                    />
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Tanggal Lahir</label>
            <div class="col-lg-8">
              <b-form-datepicker
                id="example-datepicker"
                disabled
                v-model="profil.tanggal_lahir"
                class="mb-2"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Tempat Lahir</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.tempat_lahir"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-12">
                  <!--begin::Input-->
                  <div class="form-group">
                    <!-- <label>Upload Foto Tampak Depan Kantor</label>
                                                    <div></div>
                                                    <b-form-file v-model="profil.tampak_depan"  type="file" ref="file"></b-form-file> -->
                    <!-- <div class="custom-file">
                                                     <input type="file" class="custom-file-input" id="customFile"/>
                                                     <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div> -->
                  </div>
                  <!-- <b-img :src="profil.ijazah" style="height: 100px; width: 200px;" fluid
                                        alt="Foto"></b-img> -->
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Example-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  components: { vSelect },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      status: [],
      provinsi: [
        {
          id: "",
          name: "",
        },
      ],
      selectedkota: "",
      kota: [
        {
          id: "",
          name: "",
        },
      ],
      toastCount: 0,
      profil: [],
      pendidikan: [
        { value: null, text: "Silahkan Pilih" },
        { value: "SD/Sederajat", text: "SD/Sederajat" },
        { value: "SMP/Sederajat", text: "SMP/Sederajat" },
        { value: "SMA/Sederajat", text: "SMA/Sederajat" },
        { value: "D1", text: "D1" },
        { value: "D2", text: "D2" },
        { value: "D3", text: "D3" },
        { value: "D4", text: "D4" },
        { value: "S1", text: "S1" },
        { value: "S2", text: "S2" },
        { value: "S3", text: "S3" },
      ],
      verifikasi: "verified",
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil Pengguna" }]);
    this.getprovinsi();
    this.loaddata();
  },
  methods: {
    getprovinsi() {
      axios
        .get(this.url + "/provinsis", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.provinsi = response.data;
        });
    },
    loaddata() {
      axios
        .get(this.url + `/users/` + this.$route.params.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.profil = response.data.data;
        });
    },
  },
  created() {},
};
</script>